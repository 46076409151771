"use client"
import {useSelector} from "react-redux";
import {replaceCart, selectCartState} from "../store/cart-slice";
import {useEffect, useState} from "react";
import {cartNeedsSeatsSelected, getNumSeatsNeedingToBeSelected, Seating} from "../utils/seating";
import {Cart} from "@medusajs/medusa";
import {ProductVariant} from "../models/models";
import {
    useGetSeatAllocationsMutation,
    useLazyGetProductVariantQuery, useLazyGetSeatingChartQuery,
    useReserveSeatsMutation
} from "../store/apiSlice";
import SeatingChartForm from "./seating-chart-form";
import {ToastContainer} from "react-toastify";
import {useAppDispatch} from "../store/store";
import {SeatingChartContainer} from "../app/seating-map/seating-chart-container";
import {getVariantDisplayTitle} from "./variant-info";
import {SeatNumberAndId} from "../app/seating-map/seat";
import {useRouter} from "next/navigation";
import {useToastErrorHandler} from "../hooks/toast-error-handler";

export type ReserveSeatValues = {
    cartId?: string,
    variantId: string
    seatNumbersAndIds: SeatNumberAndId[]
}

export default function SeatSelector() {
    const [seatingChartContainer, setSeatingChartContainer] = useState<SeatingChartContainer | null>()
    const [showSeatSelector, setShowSeatSelector] = useState(false)
    const [variant, setVariant] = useState<ProductVariant>()
    const [seatsSelected, setSeatsSelected] = useState<SeatNumberAndId[]>([])
    const [getVariant] = useLazyGetProductVariantQuery()
    const [getSeatAllocations] = useGetSeatAllocationsMutation()
    const [reserveSeats] = useReserveSeatsMutation()
    const [getSeatingChart] = useLazyGetSeatingChartQuery()
    const [lineItemId, setLineItemId] = useState<string>()
    const router = useRouter()
    const cart = useSelector(selectCartState) as Cart
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!cart) {
            return
        }
        setShowSeatSelector(cartNeedsSeatsSelected(cart))
    }, [cart])

    useEffect(() => {
        if (!showSeatSelector) return
        const load = async () => {
            const seating = cart.context.seating as Seating
            const variantSeating = seating.variantSeating.find(vs => vs.seatSelections.length < vs.seatSelectionsRequired)
            if (variantSeating) {
                const variant = await getVariant(variantSeating.variantId).unwrap();
                setVariant(variant)
                const seatSelectionsRequired = getNumSeatsNeedingToBeSelected(cart, variantSeating.variantId);
                const seatAllocations = await getSeatAllocations({cartId: cart.id, variantId: variantSeating.variantId}).unwrap()
                const seatingChart = await getSeatingChart(variant.seatingChart.id).unwrap()
                const lineItemId = cart.items.find(i => i.variant_id === variant.id)
                setLineItemId(lineItemId ? lineItemId.id : undefined)
                setSeatingChartContainer(new SeatingChartContainer({
                    onSeatingChartUpdate,
                    seatAllocations,
                    seatSelectionsRequired,
                    savedState: seatingChart.content,
                    showSeatingLabels: false,
                    draggable: false,
                    selectable: false,
                    seatPicking: true,
                }))
            }
        }
        load()

    }, [showSeatSelector])

    const saveSeatSelection = useToastErrorHandler(async () => {
        const params = {
            cartId: cart.id,
            variantId: variant?.id as string,
            seatNumbersAndIds: seatsSelected
        }
            const c = await reserveSeats(params).unwrap()
            setShowSeatSelector(s => false)
            setSeatingChartContainer(scc => null)
            await dispatch(replaceCart(c))
            router.push('/store/cart')
    })

    const cancelSeatSelection = async () => {
        localStorage.removeItem("cartId")
        setShowSeatSelector(false)
        window.location.reload()
    }

    function onSeatingChartUpdate (seatsSelected: SeatNumberAndId[]): undefined {
        setSeatsSelected(s => {
            return structuredClone(seatsSelected)
        })
        return undefined
    }

    if (!seatingChartContainer) return <></>
    return <div>
        <ToastContainer/>
        <dialog className={`modal ${showSeatSelector ? 'modal-open' : ''}`}>
            <div className="modal-box w-full max-w-fit">
                {
                    variant && <><div className={"flex flex-col sm:flex-row gap-x-4 gap-y-2 items-center justify-between mb-4"}>
                        <div className={"flex flex-col justify-center"}>
                            <div className={"text text-lg font-bold text-center"}>{variant.product.title}</div>
                            <div className={"text text-sm font-bold"}>{getVariantDisplayTitle(variant, false)}</div>
                        </div>
                        <div className="font-bold text-lg text-center flex flex-col gap-y-2">
                            <div>Please select <span className={"font-bold"}>{seatingChartContainer.seatSelectionsRequired}</span> seats.</div>
                            <div className={"text-gray-500 text-xs"}>You have 10 minutes to checkout after selecting seats. For support, contact info@mycomedytickets.com</div>
                        </div>

                    <div className={"flex gap-x-2"}>
                        <button className={"btn btn-error"} onClick={cancelSeatSelection}>Cancel and Start Over</button>
                        <button className={"btn btn-primary"} onClick={saveSeatSelection} disabled={seatingChartContainer.seatSelectionsRequired !== seatsSelected.length}>Save Seat Selection</button>
                    </div>
                    </div>
                    <div>


                        <SeatingChartForm bound={true}
                            seatingChartContainer={seatingChartContainer} />
                    </div>
                    </>
                }
            </div>
        </dialog>
    </div>
}