import {Cart} from "@medusajs/medusa";

export type Seating = {
    variantSeating: VariantSeating[]
}

export type VariantSeating = {
    variantId: string
    seatSelectionsRequired: number
    seatSelections: {
        seatNumber: string
    }[]
}

export function getNumSeatsNeedingToBeSelected(cart: Cart, variantId: string) {
    const seating = cart.context?.seating as Seating
    if (seating) {
        const found = seating.variantSeating.find(vs => vs.variantId === variantId)
        return found ? found.seatSelectionsRequired : 0
    }
    return 0
}

export function cartNeedsSeatsSelected(cart: Cart): boolean {
    if (!cart || !cart.context || !cart.context.seating) {
        return false
    }
    const seating = cart.context.seating as Seating
    if (!seating.variantSeating) {
        return false
    }
    return seating.variantSeating.some(vs => vs.seatSelectionsRequired !== vs.seatSelections.length)
}

export function getSelectedSeats(cart: Cart, variantId: string) {
    if (!cart || !cart.context || !cart.context.seating) {
        return []
    }
    const seating = cart.context.seating as Seating
    if (!seating.variantSeating) {
        return []
    }
    const variantSeating = seating.variantSeating.find(vs => vs.variantId === variantId && vs.seatSelectionsRequired === vs.seatSelections.length)
    if (variantSeating) {
        return variantSeating.seatSelections.map(s => s.seatNumber)
    } else {
        return []
    }
}
