import Link from "next/link";
import {classNames} from "../models/models";
import {Menu} from "@headlessui/react";
import React from "react";

export default function ReviewsButton() {
    return <Menu.Item key="reviews">
        {({active}) => (
            <Link
                href={"/store/account/reviews"}
                className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-base text-gray-700'
                )}
            >
                Reviews
            </Link>
        )}
    </Menu.Item>
}