import {useGetRegionsQuery} from "../store/apiSlice";
import {Form, Formik} from "formik";
import {useSelector} from "react-redux";
import {assignRegion, RegionStatus, selectCartState, selectRegionStatus, updateCartRegion} from "../store/cart-slice";
import {SelectInput} from "./forms/select-input";
import * as React from "react";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../store/store";
import Link from "next/link";
import {Cart} from "@medusajs/medusa";
import Image from "next/image";

type RegionSelectorValues = {
    id: string
}
export default function RegionSelector() {
    const {data: regions = []} = useGetRegionsQuery()
    const regionStatus = useSelector(selectRegionStatus)
    const cart = useSelector(selectCartState) as Cart | undefined
    const dispatch = useAppDispatch()
    const [showRegionModal, setShowRegionModal] = useState(false)

    useEffect(() => {
        if (regionStatus === RegionStatus.INITIALIZED_NOT_ASSIGNED) {
            setShowRegionModal(true)
        }
    }, [regionStatus])

    return <>{regions.length > 1 && <div className="border-b border-gray-200 bg-gray-100 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="h-8 flex items-center justify-between">
                {cart?.region && <Link href={"#"} onClick={(e) => {
                    e.preventDefault();
                    setShowRegionModal(true)
                }}>
                    <Image width={16} height={12}
                           src={`https://flagcdn.com/16x12/${cart.region.countries[0].iso_2}.png`}
                           alt={cart.region.name}/>
                </Link>}
                <Formik initialValues={{id: ''}} onSubmit={(values: RegionSelectorValues) => {
                    const region = regions.find(r => r.id === values.id)
                    if (!region) return
                    dispatch(assignRegion(region))
                    dispatch(updateCartRegion(region.id))
                    setShowRegionModal(false)
                }}>{props => <Form>
                    <div className={`modal ${showRegionModal ? 'modal-open' : ''}`}>
                        <div className="modal-box w-64">
                            <div className="py-4">
                                <div className="mb-3">
                                        <SelectInput className="w-full select select-bordered"
                                                     name="id" optionDisplayField="name"
                                                     optionValueField="id" defaultDisplay="Choose a country"
                                                     options={regions}/>
                                </div>
                            </div>
                            <div>
                                <button type={"submit"} className={"btn btn-primary w-full"}>Select</button>
                            </div>
                        </div>
                    </div>

                </Form>}</Formik>
            </div>
        </div>
    </div>}</>
}