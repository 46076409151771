import React from 'react';
import {useField} from 'formik';

export const SelectInput = ({ label, textarea, options, defaultDisplay, defaultDisplayValue, optionDisplayField, optionValueField, ...props }: any) => {
    const [field] = useField(props);
    return <>
        <label>
            {label && <div className="label">
                <span className={"label-text"}>{label}</span>
            </div>}
            <select
                className="select select-bordered"
                {...field} {...props}>
                {defaultDisplay && <option value={defaultDisplayValue ? defaultDisplayValue : ''}>{defaultDisplay}</option>}
                {options.map((o: any) => <option key={optionValueField ? o[optionValueField] : o}
                                                 value={optionValueField ? o[optionValueField] : o}>
                    {optionDisplayField && typeof optionDisplayField === "string" && o[optionDisplayField]}
                    {optionDisplayField && typeof optionDisplayField === "function" && optionDisplayField(o)}
                    {!optionDisplayField && o}
                </option>)}
            </select>
        </label>
    </>
};