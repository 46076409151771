import {
    Customer as MedusaCustomer,
    LineItem,
    Order,
    Product as MedusaProduct,
    ProductCollection,
    ProductVariant as MedusaProductVariant,
    Region,
    User as MedusaUser
} from "@medusajs/medusa";
import {PaginationInput} from "../hooks/pagination";
import {SeatStatus} from "../app/seating-map/seat";

export interface VenueArrangement {
    id: string
    name: string
    venue: Venue
    capacity: number
}

export interface Venue {
    id: string
    name: string
    address: string
    city: City
    province: string
    postalCode: string
    country: string
    phone: string
    slug: string
    timezone: string
    arrangements: VenueArrangement[]
    instagramProfile: string
    twitterProfile: string
    tiktokProfile: string
    thumbnail: string
    notificationSubscriptions: VenueNotificationSubscription[],
    ticketStrategy: string
    analyticsCode: string
    lat: number
    lon: number
}

export interface Currency {
    code: string
}

export interface Ticket {
    id: string;
    productVariant: ProductVariant;
    order: Order
    status: string;
    scannedDeviceId: string
}

export type PaginationContext = PaginationInput & {
    count: number
}

export type PaginatedList<T> = PaginationContext & {
    items: T[]
}

export type AuthError = {
    code?: string
    message: string
    type: string
}

export type User = MedusaUser &  {
    tips: Tip[]
    accounts: Account[]
    paymentDestination: PaymentDestination
    participant: Participant
}

export type Product = MedusaProduct & {
    account: Account
    venueArrangement: VenueArrangement
    venue: PlaceAddress
    activeVariants: ProductVariant[]
    inactiveVariants: ProductVariant[]
    categories: ProductCategory[]
    productContentBlocks: ProductContentBlock[]
    gigSheetTemplates: Template[]
    paymentStrategy: string
    orderCount: number
    campaigns: Campaign[]
    eligibleForContestGiveaway: boolean
    availableAt: Date
    externalOrderUrl: string
    giftCardProductCategoryRestrictions: ProductCategoryRestriction[]
    passStartsAt: Date
    passEndsAt: Date
    isPass: boolean
    isTaxable: boolean
    createdBy: User

}
export type VariantParticipant = {
    id: string
    variant: ProductVariant
    participant: Participant
    role: string
}
export type ProductVariant = MedusaProductVariant & {
    variantParticipants: VariantParticipant[]
    orderCount?: number
    ticketsSoldCount?: number
    seatingChart: SeatingChart
    seatPrices: SeatPrice[]
    startAt: Date
    endAt: Date
    startsAt: string
    lowInventoryWarningThreshold: number
}

export type Participant = {
    id: string
    name: string
    picture: string
    instagramProfile: string
    twitterProfile: string
    tiktokProfile: string
    profile: string
    slug: string
    websiteUrl: string
}

export type ParticipantUser = Participant & {
    user: User
}

export const INITIATED_AUTH_FROM_PATH = "initiatedAuthFromPath";

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export function isPaymentAccount(obj: any): obj is PaymentAccount {
    return obj !== undefined && (obj as PaymentAccount).default_currency !== undefined;
}
export interface PaymentAccount {
    id: string
    charges_enabled: boolean
    default_currency: string
    details_submitted: boolean
}

export interface AccountLink {
    url: string
    created: number
    expires_at: number
}

export type ProductPaymentInfo = {
    transferAmount: number
    salesAmount: number
}

export function isProductPaymentInfo(obj: any): obj is ProductPaymentInfo {
    return obj !== undefined &&
        (obj as ProductPaymentInfo).salesAmount !== undefined &&
        (obj as ProductPaymentInfo).transferAmount !== undefined;
}

export type Tip = {
    amount: number
    currency: string
    name: string
    note: string
    unitCount: number
    unitCost: number
}

export type Province = {
    name: string
    abbreviation: string
}

export type Country = {
    name: string
    abbreviation: string
}

export type Follow = {
    venue: Venue
}

export type Customer = MedusaCustomer & {
    venueFollows: Follow[]
}

export type NotificationType = {
    id: string
    description: string
}

export type VenueNotificationSubscription = {
    id: string
    notificationType: NotificationType
    venue: Venue
}

export type PaymentDestination = {
    id: string
    name: string
    identifier: string
    currency: string
    paymentAccountId: string
    paymentAccountMetadata: Record<string, unknown>
    paymentAccount: PaymentAccount
    enabled: boolean
    created_at: Date
}

export type Account = {
    id: string
    name: string
    storeUrl: string
    cmsApiKey: string
    cmsSecretKey: string
    productCategories: ProductCategory[]
    users: User[]
    paymentDestinations: PaymentDestination[]
    analyticsCode: string
    marketingStrategy: string
    logoImage: string
    replyToEmail: string
    fromEmailName: string
    instagramUrl: string
    facebookUrl: string
    twitterUrl: string
    brandColor1: string
    brandColor2: string
    tradableBitsAccountId: string
    paymentStrategy: string
    country: string
    province: string
    orderCompleteMessage: string
    collection: ProductCollection
    paymentCurrency: string;
    sendSaleNotificationsStrategy: SendSaleNotificationsStrategy
    reviewEmailSubject: string
    reviewEmailEditorContent: string
    reviewEmailBody: string
    reviewEmailStrategy: ReviewEmailStrategy
    chargeTaxesByDefault: boolean
}

export enum ReviewEmailStrategy {
    SEND_DEFAULT = "send-default",
    SEND_CUSTOM = "send-custom",
    DO_NOT_SEND = "do-not-send"
}

export enum FormModes {
    CREATE,
    UPDATE
}

export type ProductCategory = {
    id: string
    name: string
    handle: string
    created_at: Date
}

export type TicketGroup = {
    id: string
    tickets: Ticket[]
}

export type City = {
    id: string
    name: string
    slug: string
    thumbnail: string
}

export type EmailContent = {
    subject: string
    body: string
}

export type ProductsByRegion = {
    region: Region
    products: Product[]
}

export interface AnalyticsCode {
    analyticsCode: string
}

export type DraftOrderInvite = {
    id: string
    code: string
    draftOrderInviteVariants: DraftOrderInviteVariant[]
    maxTicketsPerShowPerEmail: number
    maxTickets: number
    defaultTicketsPerShowPerEmail: number
    totalRedemptions: number
}

type DraftOrderInviteVariant = {
    variant: ProductVariant
}

export type Review = {
    id: string
    variant: ProductVariant
    rating: number
    note: string
    customer: Customer
    participant: Participant
}

export type MailingList = {
    id: string
    account: Account
    created_at: Date
    subscriberCount: number
    slug: string

}

export type Campaign = {
    name: string
    id: string
    account: Account
    created_at: Date
    updated_at: Date
    sentAt: Date
    template?: Template
    status: string
    body: string
    subject: string
    editorContent: string
    fromEmailName: string
    replyToEmail: string
    mailingList: MailingList
    subscriberFilters: SubscriberFilter[]
    product?: Product
    scheduledAt?: Date
    schedule: CampaignSchedule
    clicks: number
    opens: number
    bounced: number
    filterStrategy: FilterStrategy
    sendStrategy: SendStrategy
    sendToSubscriberStartPosition: number
    sendToSubscriberEndPosition: number
    sentToCount: number // stored in DB after campaign is sent
    targetSubscriberCount: number // temporary field when creating campaign
    unsubscribeCount: number
    thumbnail: string
}

export type Subscription = {
    id: number
    created_at: Date
    unsubscribed_at: Date
    subscriber: Subscriber
}

export type Subscriber = {
    id: number
    name: string
    email: string
    status: string
    city: string
    province: string
    country: string
}

export type ApiError = {
    error: string[]
}

export type PlaceAddress = {
    id: string
    name: string
}

export type Feedback = {
    text: string
}

export type ContentBlock = {
    id: string
    title: string
    text: string
    thumbnail: string
    created_at: Date
}

export type ProductContentBlock = {
    contentBlock: ContentBlock
}

export type LocationResult = {
    status: string
    city: string
    lat: number
    lon: number
    country: string
    countryCode: string
    region: string
    regionName: string
    timezone: string
}

export type ProductSaleNotificationSubscription = {
    id: string
    email: string
    created_at: Date
}

export type Game = {
    id: string
    name: string
}

export type Contest = {
    id: string
    name: string
    account: Account
    contestGames: ContestGame[]
    mailingList: MailingList
    description: string
    prizeDescription: string
    subscribedLostText: string
    subscribedWonText: string
    notSubscribedLostText: string
    notSubscribedWonText: string
    winProbability: number
}

export type ContestGame = {
    contest: Contest
    game: Contest
}

export type ContestEntry = {
    id: string
    contest: Contest
    name: string
    email: string
    subscribed: boolean
    created_at: Date
    projectedWinner: boolean
}

export type ContestGameWinner = {
    id: string
    contestEntry: ContestEntry
    game: Game
    created_at: Date
}

export type Template = {
    id: string
    name: string
    text: string
    account: Account
    allTemplateVariables: TemplateVariable[]
    created_at: Date
    editorContent: string
}

export type TemplateVariable = {
    id: string
    name: string
    type: string
    tagName: string
}

export type CustomImage = {
    url: string
}


export type SubscriberFilter = {
    name: string
    label: string
    options: SubscriberFilterOption[]
    includeEmptyField: boolean
    hasEmptyField: boolean
}

export enum SendStrategy {
    SEND_NOW = "send-now",
    SEND_LATER = "send-later",
}

export type CampaignSchedule = {
    dates?: string[] // deprecated
    payloads: CampaignSchedulePayload[]
}

export type CampaignSchedulePayload = {
    date: Date,
    overrides: {
        subject: string
    }
}


export type SubscriberFilterOption = {
    name: string
    value: string
    selected: boolean
}

export type SeatingChart = {
    id: string
    name: string
    content: any
    created_at: Date
    updated_at: Date
}

export type SeatAllocation = {
    seatNumber: string
    seatId: string
    selectedInCart: boolean
    status: SeatStatus
}

export type SeatPrice = {
    currencyCode: string
    amount: number
}

export type ProductCategoryRestriction = {
    productCategory: ProductCategory
}

export type PosLocation = {
    account: Account
    paymentProcessorLocationId: string
    readers: CardReader[]
}

export type CardReader = {
    id: string
    label: string
}

export type PosStatus = {
    cardReaderId: string
    paymentStatus: string
    cardReaderStatus: string
    cardReaderLabel: string
}

export enum FilterStrategy {
    SEND_TO_ALL = "send-to-all",
    APPLY_FILTERS = "apply-filters",
    APPLY_INDEX = "apply-index",
}

export type DiscountEmailAction = {
    id?: string
    name: string
    subject: string
    content: string
    format: string
    enabled: boolean
    created_at: Date
}

export type AccountPrivilegesBare = {
    accountId: string
    privileges: string[]
}

export type Permission = {
    id: string
    name: string
    description: string
    identifier: string
}

export type AccountPrivilege = {
    id: string
    user: User
    account: Account
    permission: Permission
    updated_at: Date
}

export type ReferralAmount = {
    currency: string
    amount: number
}

export type ReferralTransaction = {
    id: string
    lineItem: LineItem
    payableAmount: number
    currency: string
}
export type Money = {
    amount: number
    currency: string
}

export type ReferredAccountTotal = {
    account: Account
    amounts: Money[]
}

export enum PaymentTransactionStatus {
    settled= "settled",
    unsettled = "unsettled"
}

export type ReferralFeeAmounts = {
    type: PaymentTransactionStatus
    amounts: Money[]
}

export enum SendSaleNotificationsStrategy {
    DO_NOT_SEND = "do-not-send",
    SEND = "send"
}

export type BatchJobLog = {
    created_at: Date
    text: string
}

export type Question = {
    id: string
    text: string
}

export type Survey = {
    id: string
    title: string
    introText: string
    questions: Question[]
}

export interface CreateAccountValues {
    currencyCode: string
    countryCode: string
    accountId?: string
}

export interface TriggerType {
    id: string
    name: string
    identifier: string
}

export interface Automation {
    id: string
    name: string
    trigger: Trigger
    action: ActionNew
    enabled: boolean
    created_at: Date
}

export interface Trigger {
    type: TriggerType
    values: TriggerValues[]
}

export interface ActionType {
    id: string
    name: string
    identifier: string
}

export interface ActionNew {
    type: ActionType
    values: ActionValues[]
}

export interface TriggerValues {
    id: string
    fieldName: string
    fieldValue: string
}

export interface ActionValues {
    fieldName: string
    fieldValue: string
}

export enum EventListFilterTypes {
    SHOW_PAST = "show-past",
    SHOW_UPCOMING = "show-upcoming",
    SHOW_ALL = "show-all"
}

export enum TicketStatus {
    CREATED = "created",
    REFUNDED = "refunded",
    CHECKED = "checked"
}

export enum MarketingStrategy {
    OPT_IN = "opt-in",
    ADD_AUTOMATICALLY = "add-automatically"
}

export type UnsubscribeStats = {
    total: number
    inLastWeek: number
    inLastMonth: number
}

export enum CampaignStatus {
    SENT = "sent",
    DRAFT = "draft",
    SCHEDULED = "scheduled"
}

export interface Respondent {
    email: string
}
export interface Answer {
    respondent: Respondent
    question: Question
    text: string
}
export interface SurveyResult {
    respondent: Respondent
    respondedAt: Date
    responses: {
        question: Question,
        answer: Answer
    }[]
}
