import {ProductVariant} from "@medusajs/medusa";
import {toShowtime} from "../utils/date";
import {Product} from "../models/models";

type VariantInfoProps = {
    variant: ProductVariant
}

export function VariantInfo({variant}: VariantInfoProps) {
    return getVariantDisplayTitle(variant)
}

export const getVariantShowtimeOnly = (variant: ProductVariant) => {
    return toShowtime(variant.startsAt)
}

export const getVariantsShowtimeOnly = (product: Product) => {
    return product.variants.map(v => getVariantShowtimeOnly(v)).join(", ")
}

export const getCondensedShowtimes = (product: Product) => {
    let result = ""
    product.variants.forEach( (value, index) => {
        result += toShowtime(value.startsAt, 'ddd MMM D, h:mm A')
        if (index !== product.variants.length-1) {
            result += ", "
        }
    })
    return result
}

export const getVariantDisplayTitle = (variant: ProductVariant, withFormatting: boolean = true) => {
    const showtime = toShowtime(variant.startsAt)
    const title = variant.title
    if (!title || title.trim() === '' || title === showtime) {
        return withFormatting === true ? <div className={"text-lg text-accent"}>{showtime}</div> : <>{showtime}</>
    } else if (showtime && title) {
        return withFormatting === true ? <><div className={"text-lg text-accent"}>{showtime}</div>
            <div className={"text-sm"}>{title}</div></> : <>{showtime} / {title}</>
    } else {
        return <div/>
    }
}
