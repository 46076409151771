import {useSelector} from "react-redux";
import {clearCartCompleteError, selectCartCompleteError} from "../store/cart-slice";
import {useAppDispatch} from "../store/store";
import {useRouter} from "next/navigation";

export default function CartCompleteError() {
    const cartCompleteError = useSelector(selectCartCompleteError)
    const dispatch = useAppDispatch()
    const router = useRouter()

    const handleClose = () => {
        dispatch(clearCartCompleteError())
        router.push("/")
    }

    return <>{cartCompleteError && <dialog id="my_modal_1" className="modal modal-open">
        <div className="modal-box">
            <h3 className="font-bold text-lg">Problem with order</h3>
            <p className="py-4">We are sorry but there was a problem with your order. This has nothing to do with you and everything to do with us.
                We already know about this error and are doing something about it, but please do email <span className={"font-bold"}>info@mycomedytickets.com</span> so we can get
                this resolved ASAP. Nothing to worry about, everything will be OK and you will get your tickets.
            </p>
            <div className="modal-action">
                <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button onClick={handleClose} className="btn">Close</button>
                </form>
            </div>
        </div>
    </dialog>}</>
}